import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Check = ({ children }) => {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("@beTVStreams"));
  
  useEffect(() => {
    const checkProfile = () => {
      if (!data) {
        navigate("/newprofile");
        return;
      }
    };
    checkProfile();
  }, [navigate, data]);

  return (children);
};

export default Check;
