import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Check from "./services/Check/Check";
import CheckProfiles from "./services/Check/CheckProfile";
import CheckXstream from "./services/Check/CheckXtream";

import New from "./pages/New/New";
import Select from "./pages/Select/Select";
import Profiles from "./pages/Profiles/Profiles";
import Browser from "./pages/Browser/Browser";
import Movies from "./pages/Movies/Movies";
import Movie from "./pages/Movie/Movie";
import PlayMovie from "./pages/Players/Movie/Movie";
import Series from "./pages/Series/Series";
import Serie from "./pages/Serie/Serie";
import PlaySerie from "./pages/Players/Serie/Serie";
import Live from "./pages/Live/Live";
import Tes from "./pages/Test/Tes";

const routes = () => {
  return (
    <>
      <ToastContainer autoClose={3000} closeOnClick theme="dark" />
      <Routes>
        <Route path="/tes" element={<Tes />} />

        {/* Route to profiles select  */}
        <Route
          path="/profiles"
          element={
            <CheckXstream>
              <Profiles />
            </CheckXstream>
          }
        />
        {/* Route to createStreamUser */}
        <Route path="/newprofile" element={<New />} />

        {/*Route to Movies page*/}
        <Route
          path="/movies"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Movies />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />

        {/*Route to Series page*/}
        <Route
          path="/series"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Series />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />

        {/*Route to Lives page*/}
        <Route
          path="/live"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Live />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />

        {/*Route to especific Serie*/}
        <Route
          path="/series/serie/:id"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Serie />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />

        {/* Route to especific Movie */}
        <Route
          path="/movies/movie/:id"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Movie />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />
        {/* Route to play Movie */}
        <Route
          path="/movies/movie/play/:id"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <PlayMovie />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />
        {/* Route to play Serie */}
        <Route
          path="/series/serie/play/:id"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <PlaySerie />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />

        {/*Route to Browser list*/}
        <Route
          path="/browser"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Browser />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        />

        {/* Route to select  */}
        <Route
          path="/select"
          element={
            <Check>
              <Select />
            </Check>
          }
        />
        {/* Route to path / */}
        <Route
          path="/"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Browser active={true} />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        ></Route>
        {/* Route to any path */}
        <Route
          path="*"
          element={
            <Check>
              <CheckXstream>
                <CheckProfiles>
                  <Browser active={true} />
                </CheckProfiles>
              </CheckXstream>
            </Check>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default routes;
