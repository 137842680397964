import "./Live.css";
import { useEffect, useState } from "react";
import Aside from '../../components/Aside/Aside'
import convert from "xml-js";

const Live = () => {
  const [epg, setEPG] = useState(null);
  useEffect(() => {
    const getEPG = async () => {
      try {
        fetch("http://pfsv.io/xmltv.php?username=9913789670&password=80139")
          .then((res) => res.text())
          .then((restext) => {
            setEPG(
              JSON.parse(
                convert.xml2json(restext, { compact: true, spaces: 1 })
              )
            );
          });
      } catch (error) {
        console.log(error);
      }
    };
    getEPG(); 
  }, []);
  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div className="browserContent moviesContent">
        <div className="moviesContainer">
          {console.log(epg)}
          <div className="topMoviesContainer"></div>
          <div className="moviesContent"> </div>
        </div>
      </div>
    </div>
  );
};

export default Live;
