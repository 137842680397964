import "./App.css";

import Routes from "./routes";

import { BrowserRouter } from "react-router-dom";
import UserProvider from "./context/userContext";
import DataProvider from "./context/dataContext";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <DataProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </DataProvider>
      </UserProvider>
    </div>
  );
}

export default App;
