import "./Browser.css";

import Aside from "../../components/Aside/Aside";
import Slider from "../../components/Slider/Slider";
import Slide from "../../components/Slider/Slide/Slide";

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import SearchBar from "../../components/SearchBar/SearchBar";
import { DataContext } from "../../context/dataContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Loading from "../../components/Loading/Loading";

const Browser = ({ active }) => {
  const navigate = useNavigate();
  const [posters, setPosters] = useState([]);
  const [recents, setRecents] = useState([]);
  const [sorted, setSorted] = useState([]);

  const { data, progress } = useContext(DataContext);
  useEffect(() => {
    console.log("get recents Storage");
    let recentStorage = JSON.parse(
      localStorage.getItem("@beTVProfileSelected")
    );
    recentStorage &&
      recentStorage.recent &&
      recentStorage.recent.length > 0 &&
      setRecents(
        recentStorage.recent.filter(
          (el) => !el.name.toLowerCase().includes("xxx")
        )
      );
  }, [data.movies, data.series, sorted]);

  useEffect(() => {
    const headerApi = {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjNTRmMGM2MDQxM2E4NzBkYzYwMTQ5OGJjMTRkYjg0ZiIsInN1YiI6IjYxZTE4YTUyMTI0MjVjMDA0NDkwNDM1NSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.hzKNDn2KEyUD5nJViXRdxJX4kMGRwrVaeCpknsYxUEs`,
      },
    };
    const getPosters = async () => {
      try {
        let response = await axios.get(
          "https://api.themoviedb.org/3/trending/all/week?language=pt-BR",
          headerApi
        );
        setPosters(response.data.results.slice(0, 10));
      } catch (error) {
        console.log(error);
      }
    };
    getPosters();
  }, [data.movies, data.series]);
  const converterTempo = (seg) => {
    let horas = Math.floor(seg / 3600)
      .toString()
      .padStart(2, "0");
    let minutos = Math.floor((seg % 3600) / 60)
      .toString()
      .padStart(2, "0");
    let segundos = Math.floor(seg % 60)
      .toString()
      .padStart(2, "0");

    let tempoFormatado = `${
      horas > 0 ? horas + ":" : ""
    }${minutos}:${segundos}`;

    return tempoFormatado;
  };

  useEffect(() => {
    let getRandom = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    let listArrays = [];
    let count = 0;
    if (data && data.movies && data.series) {
      let filtredMovies = data.movies.filter(
        (el) =>
          el.year >= 2024 &&
          el.category_id !== "41" &&
          el.rating < 10 &&
          el.rating >= 7 &&
          !el.name.includes("[") &&
          !el.name.toLowerCase().includes("4k")
      );
      let filtredSeries = data.series.filter(
        (el) => el.year >= 2024 && el.rating < 10 && el.rating >= 7
      );
      [...Array(10)].forEach(() => {
        if (count === 0) {
          listArrays = [
            ...listArrays,
            filtredMovies[getRandom(0, filtredMovies.length - 1)],
          ];

          count = 1;
        } else {
          listArrays = [
            ...listArrays,
            filtredSeries[getRandom(0, filtredSeries.length - 1)],
          ];
          count = 0;
        }
      });
      setSorted(listArrays);
    }
  }, [data, data.movies, data.series]);
  const handlePlay = (item) => {
    if (item.type === "movie") {
      localStorage.setItem("@moviePlaying", JSON.stringify(item.info));
      navigate(`/movies/movie/play/${item.id}`);
    } else if (item.type === "serie") {
      localStorage.setItem("@seriePlaying", JSON.stringify(item.info));
      navigate(
        `/series/serie/play/${item.episodes[item.episodes.length - 1].id}`
      );
    }
  };
  const handleViewRecomendation = (item) => {
    if (item.stream_type === "movie") {
      navigate(`/movies/movie/${item.stream_id}`);
    } else if (item.stream_type === "series") {
      navigate(`/series/serie/${item.series_id}`);
    }
  };
  return (
    <div className="newPageContainer browsercontainer">
      <Aside active={active} />
      <div className="browserContent">
        {!progress && data && data.movies && data.tv && data.series ? (
          <>
            <div className="topBrowser">
              <SearchBar browserSearch={true} />
              <div className="carouselContainer">
                {posters &&
                  posters.map((item, index) => (
                    <div
                      key={index}
                      className="topPosterItem"
                      style={{
                        backgroundImage: `url(https://image.tmdb.org/t/p/original${
                          item && item.backdrop_path
                        })`,
                      }}
                      title={item.name ? item.name : item.title}
                    >
                      <div className="infoTopBrowser">
                        <span>{item.name ? item.name : item.title}</span>
                        <span>{item.release_date ? "Filme" : "Serie"}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="contentBrowser">
              <div className="blocksContentBrowser">
                {recents && recents.length > 0 ? (
                  //render when have recents list
                  <>
                    <h5>Continue assistindo</h5>
                    <Slider
                      config={{
                        spacing: 10,
                      }}
                    >
                      {recents &&
                        sorted.length > 0 &&
                        recents.length > 0 &&
                        recents
                          .slice(recents.length - 11, recents.length)
                          .reverse()
                          .map((item, index) => (
                            <>
                              <Slide key={index}>
                                <motion.div
                                  className="blockBrowser"
                                  onTap={(_) => handlePlay(item)}
                                >
                                  <div
                                    className="logoContentBlock"
                                    style={{
                                      backgroundImage: `url(${
                                        item.info.info.backdrop_path
                                          ? item.info.info.backdrop_path
                                          : item.info.info.cover
                                          ? item.info.info.cover
                                          : item.info.info.stream_icon
                                          ? item.info.info.stream_icon
                                          : ""
                                      })`,
                                    }}
                                  >
                                    <span>
                                      {item.type === "movie"
                                        ? "Filme"
                                        : "Serie"}
                                    </span>
                                  </div>
                                  <div className="infoContentBlock">
                                    <span>{item.name}</span>
                                    <div className="progressContentBlock">
                                      <div className="progressContentBar">
                                        <div
                                          className="progressContentBarLoad"
                                          style={{
                                            width: `
                              ${
                                (100 *
                                  (item.time
                                    ? item.time
                                    : item.episodes[item.episodes.length - 1]
                                        .time)) /
                                (item.info.info.duration_secs
                                  ? item.info.info.duration_secs
                                  : item.info.info.episode_run_time * 60)
                              }%
                              `,
                                          }}
                                        ></div>
                                      </div>

                                      <div className="timeBlockContent">
                                        <p>
                                          {converterTempo(
                                            item.time
                                              ? item.time
                                              : item.episodes[
                                                  item.episodes.length - 1
                                                ].time
                                          )}
                                        </p>
                                        <p>
                                          {item.info.info.duration_secs
                                            ? converterTempo(
                                                item.info.info.duration_secs
                                              )
                                            : item.info.info.episode_run_time >
                                              0
                                            ? converterTempo(
                                                item.info.info
                                                  .episode_run_time * 60
                                              )
                                            : "--:--"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </motion.div>
                              </Slide>
                            </>
                          ))}
                      {recents &&
                        recents.length < 10 &&
                        sorted &&
                        sorted
                          .slice(0, 10 - recents.length)
                          .map((item, index) => (
                            <Slide key={index}>
                              <motion.div
                                className="blockBrowser"
                                onTap={(_) => handleViewRecomendation(item)}
                              >
                                <div
                                  className="logoContentBlock"
                                  style={{
                                    backgroundImage: `url(${
                                      item.backdrop_path
                                        ? item.backdrop_path
                                        : item.cover
                                        ? item.cover
                                        : item.stream_icon
                                        ? item.stream_icon
                                        : ""
                                    })`,
                                  }}
                                >
                                  <span>
                                    {item.stream_type === "movie"
                                      ? "Filme"
                                      : "Serie"}
                                  </span>
                                </div>
                                <div className="infoContentBlock">
                                  <span>{item.name}</span>
                                  <h4>Recomendação</h4>
                                </div>
                              </motion.div>
                            </Slide>
                          ))}
                    </Slider>
                  </>
                ) : (
                  //render when no have recents list
                  <>
                    <h5>
                      Ainda não assistiu nada ? Veja algumas recomendações
                    </h5>
                    <Slider
                      config={{
                        spacing: 10,
                      }}
                    >
                      {data &&
                        data.movies &&
                        data.series &&
                        sorted.length >= 9 &&
                        sorted.map((item, index) => (
                          <Slide key={index}>
                            <motion.div
                              className="blockBrowser"
                              onTap={(_) => handleViewRecomendation(item)}
                            >
                              <div
                                className="logoContentBlock"
                                style={{
                                  backgroundImage: `url(${
                                    item.backdrop_path
                                      ? item.backdrop_path
                                      : item.cover
                                      ? item.cover
                                      : item.stream_icon
                                      ? item.stream_icon
                                      : ""
                                  })`,
                                }}
                              >
                                <span>
                                  {item.stream_type === "movie"
                                    ? "Filme"
                                    : "Serie"}
                                </span>
                              </div>
                              <div className="infoContentBlock">
                                <span>{item.name}</span>
                              </div>
                            </motion.div>
                          </Slide>
                        ))}
                    </Slider>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="newBackground backgroundBrowser"></div>
    </div>
  );
};

export default Browser;
