import "./Series.css";
import Aside from "../../components/Aside/Aside";

import { DataContext } from "../../context/dataContext";
import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar/SearchBar";
import Slider from "../../components/Slider/Slider";
import Slide from '../../components/Slider/Slide/Slide'
import {motion} from 'framer-motion'
import Loading from "../../components/Loading/Loading";

const Series = () => {
  const [page, setPage] = useState(1);
  const [pageInit, setPageInit] = useState(0);
  const [pageFim, setPageFim] = useState(145);
  const { data, categories, progress, changeCategorySerie, categorySerie } =
    useContext(DataContext);
  const [selected, setSelected] = useState(categorySerie ? categorySerie : 0);
  const navigate = useNavigate();
  const container = useRef();
  const handleNext = () => {
    container.current.scrollTop = 0;
    if (selected !== 0) {
      if (
        data &&
        data.series &&
        data.series
          .filter((el) =>
            el.category_ids.find((dat) => dat === parseInt(selected))
          )
          .slice(pageInit, pageFim).length < 145
      ) {
        toast.warn("Você já esá na ulima pagina");
      } else {
        if (page >= 1) {
          pageInit >= 145 ? setPageInit(pageInit + 145) : setPageInit(145);
          pageFim >= 145 ? setPageFim(pageFim + 145) : setPageFim(145);
          setPage(page + 1);
        }
      }
    } else {
      if (
        data &&
        data.series &&
        data.series.slice(pageInit, pageFim).length < 145
      ) {
        toast.warn("Você já esá na ulima pagina");
      } else {
        if (page >= 1) {
          pageInit >= 145 ? setPageInit(pageInit + 145) : setPageInit(145);
          pageFim >= 145 ? setPageFim(pageFim + 145) : setPageFim(145);
          setPage(page + 1);
        }
      }
    }
  };

  const handlePrev = () => {
    container.current.scrollTop = 0;
    if (page >= 2) {
      pageInit > 145 ? setPageInit(pageInit - 145) : setPageInit(0);
      pageFim > 145 ? setPageFim(pageFim - 145) : setPageFim(145);
      setPage(page - 1);
    } else {
      toast.warn("Você já esta na primeira pagina");
    }
  };

  const handleSelect = (id) => {
    setPage(1);
    setPageInit(0);
    setPageFim(145);
    changeCategorySerie(id);
    id ? setSelected(id) : setSelected(0);
  };
  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div className="browserContent moviesContent">
        {/* Modal for set adullt password */}

        {!progress && data && data.series ? (
          <div className="moviesContainer" ref={container}>
            <div className="topMoviesContainer">
              <Slider>
                <Slide>
                  <motion.div
                  className="span"
                    onClick={(e) => handleSelect()}
                    style={{
                      backgroundColor:
                        selected === 0
                          ? "var(--yellowHover)"
                          : "var(--bgTransparent)",
                      color: selected === 0 ? "var(--black)" : "white",
                    }}
                  >
                    Todos
                  </motion.div>
                </Slide>
                {categories &&
                  categories.series &&
                  categories.series.map((item, index) => (
                    <Slide key={index}>
                      <motion.div
                      className="span"
                        onClick={(e) => handleSelect(item.category_id)}
                        category={item}
                        style={{
                          backgroundColor:
                            selected === item.category_id
                              ? "var(--yellowHover)"
                              : "var(--bgTransparent)",
                          color:
                            selected === item.category_id
                              ? "var(--black)"
                              : "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.category_name.split("|").length > 1
                          ? item.category_name.split("|")[1]
                          : item.category_name.split("|")[0]}
                      </motion.div>
                    </Slide>
                  ))}
              </Slider>
            </div>
            <div className="moviesPagination">
              <span className="pageDesc">Pagina {page}</span>
              <div
                className="
              searchAndControl"
              >
                <SearchBar pagesSearch navigation={true} activePage={"series"} />
              </div>
              <div className="controlsPage">
                <span onClick={(_) => handlePrev()}>Anterior</span>
                <span onClick={(_) => handleNext()}>Proxima</span>
              </div>
            </div>
            <div className="moviesContent">
              {data && data.series && data.series.length >= 1 ? (
                selected === 0 ? (
                  // show all movies
                  data &&
                  data.series &&
                  data.series.slice(pageInit, pageFim).map((item, index) => (
                    //normal content box
                    <div
                      className="movieBox"
                      key={index}
                      title={item.name}
                      onClick={(_) =>
                        navigate(
                          `/series/serie/${
                            item.stream_id ? item.stream_id : item.series_id
                          }`
                        )
                      }
                    >
                      <div
                        className="moviePoster"
                        style={{
                          backgroundImage: `url('${
                            item.stream_icon ? item.stream_icon : item.cover
                          }')`,
                        }}
                      >
                        {item.year && (
                          <div className="moviePosterInfo movieYear">
                            {item.year}
                          </div>
                        )}
                        {item.rating > 0 && (
                          <div className="moviePosterInfo movieNote">
                            {parseFloat(item.rating).toFixed(1)}
                          </div>
                        )}

                        {item.name.toLowerCase().includes("[l]") && (
                          <div className="moviePosterInfo movieLegend">L</div>
                        )}
                      </div>
                      <div className="movieInfos">
                        <div className="movieTitle">
                          {item.name.split("[").length > 1
                            ? item.name.split("[")[0]
                            : item.name.split("(")[0]}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  //show category movie

                  data &&
                  data.series &&
                  data.series
                    .filter((el) =>
                      el.category_ids.find((dat) => dat === parseInt(selected))
                    )
                    .slice(pageInit, pageFim)
                    .map((item, index) => (
                      //normal content box
                      <div
                        className="movieBox"
                        key={index}
                        title={item.name}
                        onClick={(_) =>
                          navigate(
                            `/series/serie/${
                              item.stream_id ? item.stream_id : item.series_id
                            }`
                          )
                        }
                      >
                        <div
                          className="moviePoster"
                          style={{
                            backgroundImage: `url('${
                              item.stream_icon ? item.stream_icon : item.cover
                            }')`,
                          }}
                        >
                          {item.year && (
                            <div className="moviePosterInfo movieYear">
                              {item.year}
                            </div>
                          )}
                          {item.rating > 0 && (
                            <div className="moviePosterInfo movieNote">
                              {parseFloat(item.rating).toFixed(1)}
                            </div>
                          )}

                          {item.name.toLowerCase().includes("[l]") && (
                            <div className="moviePosterInfo movieLegend">L</div>
                          )}
                        </div>
                        <div className="movieInfos">
                          <div className="movieTitle">
                            {item.name.split("[").length > 1
                              ? item.name.split("[")[0]
                              : item.name.split("(")[0]}
                          </div>
                        </div>
                      </div>
                    ))
                )
              ) : (
                <h1>Nenhuma serie encontrada</h1>
              )}
            </div>
            <div className="moviesPagination">
              <span className="pageDesc">Pagina {page}</span>
              <div className="controlsPage">
                <span onClick={(_) => handlePrev()}>Anterior</span>
                <span onClick={(_) => handleNext()}>Proxima</span>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Series;
