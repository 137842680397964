import "./Movie.css";
import ReactPlayer from "react-player";
import { useContext, useEffect, useState, useRef } from "react";

import Aside from "../../../components/Aside/Aside";
import { UserContext } from "../../../context/userContext";
import { DataContext } from "../../../context/dataContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading/Loading";

const Movie = () => {
  const [movie, setMovie] = useState(undefined);
  const [profiles, setProfiles] = useState([]);
  const { userData, user } = useContext(UserContext);
  const { data } = useContext(DataContext);
  const [movieDone, setMovieDone] = useState(false);
  const navigate = useNavigate();
  const player = useRef();
  useEffect(() => {
    let mov = JSON.parse(localStorage.getItem("@moviePlaying"));
    mov && setMovie(mov);

    let getProfiles = JSON.parse(localStorage.getItem("@beTVProfiles"));
    getProfiles && setProfiles(getProfiles);
  }, []);

  const handleSaveTime = () => {
    let newProfiles = profiles.filter((el) => el.id !== user.id);
    let userWatching = JSON.parse(localStorage.getItem("@beTVProfileSelected"));
    //check if user exists
    if (userWatching) {
      //if exists check if recent lists exists
      if (userWatching.recent) {
        let userInfoHave = userWatching;
        //check if movie exists in list
        let havemovie = userWatching.recent.find((el) => el.id === movie.movie_data.stream_id);
        if (havemovie) {
          //if movie exists in list made
          //generate list without movie
          let listnoMovie = userWatching.recent.filter(
            (dat) => dat.id !== movie.movie_data.stream_id
          );
          //
          userInfoHave.recent = [
            ...listnoMovie,
            {
              type: 'movie',
              name: movie.movie_data.title,
              info: movie,
              id: movie.movie_data.stream_id,
              time: player.current.getCurrentTime(),
            },
          ];
          localStorage.setItem(
            "@beTVProfiles",
            JSON.stringify([...newProfiles, userInfoHave])
          );
          localStorage.setItem(
            "@beTVProfileSelected",
            JSON.stringify(userInfoHave)
          );
        } else {
          let userMoviedontHave = {
            id: userWatching.id,
            name: userWatching.name,
            pathID: userWatching.pathID,
            url: userWatching.url,
            recent: [...userInfoHave.recent,
              {
                type: 'movie',
                name: movie.movie_data.title,
                info: movie,
                id: movie.movie_data.stream_id,
                time: player.current.getCurrentTime(),
              },
            ],
          };
          localStorage.setItem(
            "@beTVProfiles",
            JSON.stringify([...newProfiles, userMoviedontHave])
          );
          localStorage.setItem(
            "@beTVProfileSelected",
            JSON.stringify(userMoviedontHave)
          );
        }
      } else {
        let userInfoNew = {
          id: userWatching.id,
          name: userWatching.name,
          pathID: userWatching.pathID,
          url: userWatching.url,
          recent: [
            {
              type: 'movie',
              name: movie.movie_data.title,
              info: movie,
              id: movie.movie_data.stream_id,
              time: player.current.getCurrentTime(),
            },
          ],
        };
        //if dont exists, create de recent lists
        localStorage.setItem(
          "@beTVProfiles",
          JSON.stringify([...newProfiles, userInfoNew])
        );
        localStorage.setItem(
          "@beTVProfileSelected",
          JSON.stringify(userInfoNew)
        );
      }
    }
  };
  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div
        className={`browserContent moviePlayerContent ${
          movieDone ? "movieDone" : ""
        }`}
      >
        {movie && userData && data ? (
          <>
            <ReactPlayer
              ref={player}
              onProgress={(_) => handleSaveTime()}
              playing
              onReady={(_) => setMovieDone(true)}
              onError={(err) => {
                console.log(err);
                toast.error(
                  "Ocorreu um erro, tente novamente ou entre em contato com o seu provedor para detalhes"
                );
                setTimeout(() => {
                  navigate(`/movies/movie/${movie.movie_data.stream_id}`);
                }, 3000);
              }}
              controls
              url={`${userData.dado.url}/movie/${userData.dado.user}/${userData.dado.password}/${movie.movie_data.stream_id}.${movie.movie_data.container_extension}`}
            />
            {!movieDone && (
              <Loading />
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Movie;
