import "./Select.css";

import { UserContext } from "../../context/userContext";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import {
  FaPenAlt,
  FaTrashAlt,
  FaPlus,
  FaTag,
  FaLink,
  FaUser,
  FaAsterisk,
  FaCalendarPlus,
  FaCalendarTimes,
  FaPowerOff,
} from "react-icons/fa";
import { FaDisplay } from "react-icons/fa6";
const Select = () => {
  const { streams, changeUser, changeNew,changeStream } = useContext(UserContext);
  const navigate = useNavigate();
  const handleAdd = () => {
    streams && streams.length >= 4
      ? toast.error(
          "Você atingiu o limite de usuarios por favor exclua um para continuar"
        )
      : navigate("/newprofile");
  };

  const handleDelete = (item) => {
    let lista = streams.filter((el)=>{
      return  el.dado.name !== item.dado.name
    })
    changeUser(lista)
    changeNew()
  };
  // const handleEdit = () => {};

  const handleSelect = (item)=>{
    localStorage.setItem("@beTVStreamSelected",JSON.stringify(item))
    changeStream(item)
    navigate('/browser')
  }
  return (
    <div className="newPageContainer containerSelect">
      <h2 onClick={(_) => handleAdd()}>
        Adicionar <FaPlus />{" "}
      </h2>
      <div className="containerSelectStream">
        {streams &&
          streams.map((item, index) => (
            <div className="streamInfo" key={index} onClick={_=>handleSelect(item)}>
              <div className="streamData">
                <span>
                  <FaTag size={12} />
                  {item.dado.name}
                </span>
                <span>
                  <FaLink size={12} />
                  {item.dado.url}
                </span>
                <span>
                  <FaUser size={12} />
                  {item.dado.user}
                </span>
                <span>
                  <FaAsterisk size={12} />
                  {item.dado.password}
                </span>
              </div>
              <div className="infoStreamData">
                <div>
                  <span>
                    <FaCalendarPlus size={12} />
                    {format(
                      new Date(item.info.created_at * 1000),
                      "dd/MM/yyyy"
                    )}
                  </span>
                  <span>
                    <FaCalendarTimes size={12} />
                    {item.info.exp_date
                      ? format(
                          new Date(item.info.exp_date * 1000),
                          "dd/MM/yyyy"
                        )
                      : "?"}
                  </span>
                </div>
                <span>
                  <FaDisplay size={12} />
                  Telas : {item.info.max_connections}
                </span>
                <span>
                  <FaPowerOff size={12} />
                  {item.info.status === "Active" ? "Ativo" : "Desativado"}
                </span>
              </div>
              <div className="streamControl">
                <FaPenAlt size={22} />
                <FaTrashAlt size={22}onClick={_=>handleDelete(item)} />
              </div>
            </div>
          ))}
      </div>
      <div className="newBackground backgroundBrowser"></div>
    </div>
  );
};

export default Select;
