import { createContext, useState, useEffect } from "react";

export const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [controlNew, setControlNew] = useState(false);
  const [user, setUser] = useState({});
  const [stream, setStream] = useState({});
  const [userData, setUserData] = useState(null);
  const [streams, setStreams] = useState([]);

  useEffect(() => {
    const getStreams = async () => {
      console.log("getting streams info");
      if (localStorage.getItem("@beTVStreamSelected")) {
        setUserData(
          await JSON.parse(localStorage.getItem("@beTVStreamSelected"))
        );
      }
    };
    getStreams();
  }, [controlNew]);

  useEffect(() => {
    const getStreamsList = async () => {
      if (localStorage.getItem("@beTVStreams")) {
        setStreams(await JSON.parse(localStorage.getItem('@beTVStreams')));
      }
    };
    getStreamsList();
  }, [controlNew]);
  useEffect(() => {
    const getProfile = async () => {
      let userInfo = await JSON.parse(
        localStorage.getItem("@beTVProfileSelected")
      );
      setUser(userInfo);
    };
    getProfile();
  }, [controlNew]);

  const changeUser = (user) => {
    localStorage.setItem("@beTVStreams", JSON.stringify(user));
    setUserData(user);
  };
  const changeNew = () => {
    setControlNew(!controlNew);
  };
  const changeProfile = (user) => {
    setUser(user);
  };
  const changeStream = (stream) => {
    setStream(stream);
  };
  return (
    <UserContext.Provider
      value={{
        changeUser,
        userData,
        changeNew,
        user,
        stream,
        streams,
        changeProfile,
        changeStream,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
