import "./Back.css";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Back = ({route}) => {
  const navigate = useNavigate();
  return (
    <div className="beckPage" onClick={(_) => navigate(`${route}`)}>
      <FaArrowLeft size={18} /> Voltar
    </div>
  );
};

export default Back;
