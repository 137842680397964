import "./Profiles.css";
import { useContext, useEffect, useState } from "react";
import { FaPencilAlt, FaPlus, FaTrash, FaUser } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { DataContext } from "../../context/dataContext";

const Profiles = () => {
  const [perfis, setPerfis] = useState([]);
  const [newProf, setNewProf] = useState(false);
  const [selected, setSelected] = useState(1);
  const [editID, setEditID] = useState("");
  const [name, setName] = useState("");
  const [controlEdit, setControlEdit] = useState(false);
  const [profileSelected, setProfileSelected] = useState({});
  const [changedProfile, setChangedProfile] = useState(false);

  const { changeProfile } = useContext(UserContext);
  const { data, progress } = useContext(DataContext);

  const navigate = useNavigate();
  useEffect(() => {
    const getProfiles = () => {};
    let prof = JSON.parse(localStorage.getItem("@beTVProfiles"));
    if (prof) {
      setPerfis(prof);
    }
    getProfiles();
  }, []);
  const handleCancel = () => {
    setSelected(1);
    setName("");
    setEditID("");
    setName("");
    setControlEdit(false);
    setNewProf(false);
  };
  const handleCreateprofile = () => {
    if (perfis.length >= 10) {
      toast.warn("Você já atingiu o limite de perfis, por favor remova um");
    } else {
      if (perfis.length > 0) {
        let profileToset = {
          pathID: selected,
          id: `${
            Math.floor(Math.random() * (300 - 50 + 1)) + 50
          }${selected}${name}${
            Math.floor(Math.random() * (300 - 50 + 1)) + 50
          }`,
          name,
          url: `./avatar/avatar (${selected}).png`,
        };
        localStorage.setItem(
          "@beTVProfiles",
          JSON.stringify([...perfis, profileToset])
        );
        setPerfis([...perfis, profileToset]);
        handleCancel();
      } else {
        let profileToset = [
          {
            pathID: selected,
            id: `${
              Math.floor(Math.random() * (300 - 50 + 1)) + 50
            }${selected}${name}${
              Math.floor(Math.random() * (300 - 50 + 1)) + 50
            }`,
            name,
            url: `./avatar/avatar (${selected}).png`,
          },
        ];
        localStorage.setItem("@beTVProfiles", JSON.stringify(profileToset));
        setPerfis(profileToset);
        handleCancel();
      }
    }
  };

  const handleExclude = (item, index) => {
    let newList = perfis.filter((el, ind) => {
      return ind !== index;
    });
    if (newList) {
      setPerfis(newList);
      localStorage.setItem("@beTVProfiles", JSON.stringify(newList));
    }
  };
  const handleEdit = (item) => {
    setControlEdit(true);
    setNewProf(true);
    setName(item.name);
    setEditID(item.id);
    setSelected(item.pathID);
  };
  const handleUpdateProfile = () => {
    let data = perfis.find((el, index) => {
      return el.id === editID;
    });
    let newlist = perfis.filter((el, index) => {
      return el.id !== editID;
    });
    let item = {
      pathID: selected,
      id: editID,
      url: `./avatar/avatar (${selected}).png`,
      name,
    };
    if (data && newlist) {
      setPerfis([...newlist, item]);
      localStorage.setItem("@beTVProfiles", JSON.stringify([...newlist, item]));
      handleCancel();
    }
  };

  const handleChangeProfile = (item, index) => {
    setChangedProfile(true);
    setTimeout(() => {
      localStorage.setItem("@beTVProfileSelected", JSON.stringify(item));
      setProfileSelected(item);
    }, 1000);
    changeProfile(item)
    setTimeout(() => {
      !progress &&
      data &&
      data.movies &&
      data.series &&
      data.tv &&
      navigate("/browser");
    }, 3000);
  };

  return (
    <div className="newPageContainer">
      <div className="logoTv logoProfiles"></div>
      {changedProfile && profileSelected.pathID && (
        <div
          className="loadingProfileSelected"
          style={{
            backgroundImage: `url('./avatar/avatar (${profileSelected.pathID}).png')`,
          }}
        >
          <div className="avatarLoading"></div>
          <div className="loadingSpin">
            <div className="loadSpinBall"></div>
          </div>
        </div>
      )}
      <div
        className={changedProfile ? "addProfile hideOnchange" : "addProfile"}
      >
        <h1>Quem está assistindo ?</h1>
        <div className="profilerSelect">
          {perfis && perfis.length > 0 ? (
            <ul className="profilesUL">
              <div className="profilesListBox">
                <ul>
                  {perfis &&
                    perfis.map((item, index) => (
                      <li
                        key={index}
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleChangeProfile(item, index)
                        }
                      >
                        <div
                          tabIndex={2}
                          onClick={(e) => handleChangeProfile(item, index)}
                          className="profileAvatar"
                          style={{
                            backgroundImage: `url('${item.url}')`,
                          }}
                        ></div>
                        <div
                          className="excludeProfile"
                          onClick={(_) => handleExclude(item, index)}
                        >
                          <FaTrash />
                        </div>
                        <div className="profileName">
                          <span>
                            {item.name}
                            <FaPencilAlt
                              size={18}
                              onClick={(e) => handleEdit(item)}
                            />
                          </span>
                        </div>
                      </li>
                    ))}
                  {perfis.length < 10 && (
                    <li
                      className="profilerBox newProfile"
                      onClick={(_) => setNewProf(true)}
                    >
                      <div
                        className="profileAvatar noProfileAvatar"
                        tabIndex={3}
                        onKeyDown={(e) => e.key === "Enter" && setNewProf(true)}
                      >
                        <FaUser size={44} />
                      </div>
                      <div className="profileName ">
                        <span>
                          Criar perfil <FaPlus />
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </ul>
          ) : (
            <ul className="profilesUL">
              <li
                className="profilerBox newProfile"
                onClick={(_) => setNewProf(true)}
              >
                <div className="profileAvatar noProfileAvatar">
                  <FaUser size={44} />
                </div>
                <div className="profileName">
                  <span>Criar perfil</span>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
      {newProf && (
        <>
          <div
            className="createProfile"
            onClick={(_) => setNewProf(false)}
          ></div>
          <div className="createProfileForm">
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                tabIndex={1}
                type="text"
                name="name"
                id="name"
                placeholder="Digite um nome"
              />
              <div className="avatarSelect">
                <span>Escolha um avatar:</span>
                <ul>
                  {[...Array(60)].map((item, index) => (
                    <li
                      key={index}
                      onClick={(e) => setSelected(index + 1)}
                      onKeyDown={(e) =>
                        e.key === "Enter" && setSelected(index + 1)
                      }
                    >
                      <div
                        tabIndex={2}
                        className="avatarIcon"
                        style={{
                          backgroundImage: `url('./avatar/avatar (${
                            index + 1
                          }).png')`,
                          filter: `${
                            selected === index + 1 ? "contrast(60%)" : ""
                          }`,
                        }}
                      ></div>
                    </li>
                  ))}
                </ul>
                <div className="buttonsControler">
                  <button onClick={(_) => handleCancel()} tabIndex={3}>
                    Cancelar
                  </button>
                  {controlEdit ? (
                    <button onClick={(_) => handleUpdateProfile()} tabIndex={4}>
                      Salvar
                    </button>
                  ) : (
                    <button onClick={(_) => handleCreateprofile()} tabIndex={4}>
                      Salvar
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      <div className="newBackground"></div>
    </div>
  );
};

export default Profiles;
