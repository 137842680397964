import { useRef, useState } from "react";
import "./Slider.css";
import Wraper from "./Wraper/Wraper";
import { motion } from "framer-motion";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";

const Slider = ({ children, config, bgColor, controls }) => {
  const [distance, setDistance] = useState(0);
  const sliderContainerRef = useRef();

  const handleMoveLeft = () => {
    if (-sliderContainerRef.current.offsetWidth < distance) {
      setDistance(distance - 100);
    }
  };
  const handleMoveRight = () => {
    distance < 0 && setDistance(distance+100)
  };
  return (
    <motion.div
      className="sliderContainer"
      ref={sliderContainerRef}
      style={{ backgroundColor: bgColor }}
    >
      {controls && (
        <div
          className="controlArrow arrowLeftSlider"
          onClick={(_) => handleMoveRight()}
        >
          <MdOutlineKeyboardDoubleArrowLeft size={26} />
        </div>
      )}
      <Wraper
        distance={distance}
        gap={config ? (config.spacing ? config.spacing : 10) : 10}
        refContainer={sliderContainerRef}
        controls={controls}
      >
        {children}
      </Wraper>
      {controls && (
        <div
          className="controlArrow arrowRightSlider"
          onClick={(_) => handleMoveLeft()}
        >
          <MdOutlineKeyboardDoubleArrowRight size={26} />
        </div>
      )}
    </motion.div>
  );
};

export default Slider;
