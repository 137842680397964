import "./Serie.css";
import ReactPlayer from "react-player";
import { useContext, useEffect, useRef, useState } from "react";

import Aside from "../../../components/Aside/Aside";
import { UserContext } from "../../../context/userContext";
import { DataContext } from "../../../context/dataContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading/Loading";

const Serie = () => {
  const [serie, setSerie] = useState(undefined);
  const { userData } = useContext(UserContext);
  const { data } = useContext(DataContext);
  const [timeSave, setTimeSaved] = useState(0);
  const [movieDone, setMovieDone] = useState(false);
  const player = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    let serieStorage = JSON.parse(localStorage.getItem("@seriePlaying"));
    serieStorage && setSerie(serieStorage);
  }, []);

  const handleGetTime = () => {
    let user = JSON.parse(localStorage.getItem("@beTVProfileSelected"));
    let userList = JSON.parse(localStorage.getItem("@beTVProfiles"));
    let listWithoutUser = userList.filter((el) => el.id !== user.id);
    if (user && userList) {
      //check if user have recents list
      if (user.recent) {
        //filter series recent list
        let onlySeriesList = user.recent.find((el) => el.type === "serie");
        if (onlySeriesList) {
          //have series in list
          //verify if series contain i list
          let serieFindedInRecents = user.recent.find(
            (it) => it.type === "serie" && it.name === serie.info.name
          );
          if (serieFindedInRecents) {
            //serie finded in recents, do
            //check if episodes exists
            let haveEpisode = serieFindedInRecents.episodes.find(
              (ep) => ep.id === id
            );
            if (haveEpisode) {
              //if episode , do
              //create new list without episode
              let listWithoutEpisode = serieFindedInRecents.episodes.filter(
                (eps) => eps.id !== id
              );
              serieFindedInRecents.episodes = [
                ...listWithoutEpisode,
                {
                  id,
                  time: player.current.getCurrentTime(),
                },
              ];
              let newListwithoutSerie = user.recent.filter(
                (dado) =>
                  dado.type === "movie" ||
                  (dado.type === "serie" && dado.name !== serie.info.name)
              );
              user.recent = [...newListwithoutSerie, serieFindedInRecents];
              localStorage.setItem(
                "@beTVProfileSelected",
                JSON.stringify(user)
              );
              localStorage.setItem(
                "@beTVProfiles",
                JSON.stringify([...listWithoutUser, user])
              );
            } else {
              //haven't episode, do
              serieFindedInRecents.episodes = [
                ...serieFindedInRecents.episodes,
                {
                  id,
                  time: player.current.getCurrentTime(),
                },
              ];
              //create new list of recent without serie
              let newListwithoutSerie = user.recent.filter(
                (dado) =>
                  dado.type === "movie" ||
                  (dado.type === "serie" && dado.name !== serie.info.name)
              );
              user.recent.push = [...newListwithoutSerie, serieFindedInRecents];
              localStorage.setItem(
                "@beTVProfileSelected",
                JSON.stringify(user)
              );
              localStorage.setItem(
                "@beTVProfiles",
                JSON.stringify([...listWithoutUser, user])
              );
            }
          } else {
            //serie dont finded in recents, do

            user.recent = [
              ...user.recent,
              {
                type: "serie",
                name: serie.info.name,
                info: serie,
                episodes: [
                  {
                    id,
                    time: player.current.getCurrentTime(),
                  },
                ],
              },
            ];
            localStorage.setItem("@beTVProfileSelected", JSON.stringify(user));
            localStorage.setItem(
              "@beTVProfiles",
              JSON.stringify([...listWithoutUser, user])
            );
          }
        } else {
          user.recent = [
            ...user.recent,
            {
              type: "serie",
              name: serie.info.name,
              info: serie,
              episodes: [
                {
                  id,
                  time: player.current.getCurrentTime(),
                },
              ],
            },
          ];
          localStorage.setItem("@beTVProfileSelected", JSON.stringify(user));
          localStorage.setItem(
            "@beTVProfiles",
            JSON.stringify([...listWithoutUser, user])
          );
        }
      } else {
        //create recents listi with serie
        user.recent = [{
          type: "serie",
          name: serie.info.name,
          info: serie,
          episodes: [
            {
              id,
              time: player.current.getCurrentTime(),
            },
          ],
        }];
        localStorage.setItem("@beTVProfileSelected", JSON.stringify(user));
        localStorage.setItem(
          "@beTVProfiles",
          JSON.stringify([...listWithoutUser, user])
        );
      }
    }
  };
  console.log(timeSave)
  useEffect(() => {
    const setTime = () => {
      let serieStorage = JSON.parse(
        localStorage.getItem("@beTVProfileSelected")
      );
      if (serieStorage.recent) {
        let separeSeries = serieStorage.recent.filter(
          (el) => el.type === "serie"
        );
        if (separeSeries) {
          separeSeries.forEach((item) => {
            item.episodes.forEach((dado) => {
              if (dado.id === id) {
                setTimeSaved(dado.time);
                
              }
            });
          });
        }
      }
    };
    setTime();
  }, [id]);
  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div
        className={`browserContent moviePlayerContent ${
          movieDone ? "movieDone" : ""
        }`}
      >
        {serie && userData && data ? (
          <>
            <ReactPlayer
              onProgress={(_) => handleGetTime()}
              ref={player}
              playing
              onReady={(_) => {
                setMovieDone(true);
              }}
              onError={(err) => {
                console.log(err);
                toast.error(
                  "Ocorreu um erro, tente novamente ou entre em contato com o seu provedor para detalhes"
                );
                setTimeout(() => {
                  navigate(`/series/serie/${serie.serie_id}`);
                }, 3000);
              }}
              controls
              url={`${userData.dado.url}/series/${userData.dado.user}/${userData.dado.password}/${id}`}
            />{" "}
            {!movieDone && (

              <Loading />
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Serie;
