import { createContext, useState, useContext, useEffect } from "react";
import { UserContext } from "./userContext";

import axios from "axios";

export const DataContext = createContext({});

const DataProvider = ({ children }) => {
  const [controlNew, setControlNew] = useState(false);
  const [data, setData] = useState([]);
  const [listSearch,setListSearch] = useState([])
  const [categories,setCategories] = useState([])
  const [progress, setProgress] = useState(true);
  const [progressSerie, setProgressSerie] = useState(true);
  const [progressTV, setProgressTV] = useState(true);
  const { userData } = useContext(UserContext);
  const [allowAdult,setAllowAdult] = useState(false);
  const [categorySerie,setCategorySerie] = useState(0)
  const [categoryMovie,setCategoryMovie] = useState(0)
  const [searchResults,setSeachResults] = useState([])

  useEffect(() => {
    setProgress(true);
    if (userData) {
      console.log("UserData loaded");
      let dado = userData.dado;
      let catStreams = {}
      let dataXtream = {};
      let path =
        dado &&
        `${dado.url}/player_api.php?username=${dado.user}&password=${dado.password}`;
      const getMovies = async () => {
        try {
          let resp = await axios.get(`${path}&action=get_vod_streams`);
          let respCat = await axios.get(`${path}&action=get_vod_categories`);
          dataXtream.movies = resp.data;
          catStreams.movies = respCat.data
          setProgress(false);
        } catch (error) {
          setProgress(false);
          console.log(error);
        }
      };
      const getSeries = async () => {
        try {
          let resp = await axios.get(`${path}&action=get_series`);
          let respCat = await axios.get(`${path}&action=get_series_categories`);
          catStreams.series = respCat.data
          dataXtream.series = resp.data;
          setProgressSerie(false);
        } catch (error) {
          setProgressSerie(false);
          console.log(error);
        }
      };
      const getTV = async () => {
        try {
          let resp = await axios.get(`${path}&action=get_live_streams`);
          let respCat = await axios.get(`${path}&action=get_live_categories`);
          catStreams.tv = respCat.data
          dataXtream.tv = resp.data;

          setProgressTV(false);
        } catch (error) {
          setProgressTV(false);
          console.log(error);
        }
      };
      getMovies();
      getTV();
      getSeries();
      setCategories(catStreams)
      setData(dataXtream);
      setListSearch(dataXtream)
    } 
  }, [userData, controlNew]);

  const controlNewData = () => {
    setControlNew(!controlNew);
  };
  const addData = (item) => {
    setData(item);
  };
  const addList = (item)=>{
    setListSearch(item)
  }
  const AllowAdultContent = (control)=>{
    setAllowAdult(control)
  }
  const changeCategorySerie = (id)=>{
    setCategorySerie(id)
  }
  const changeCategoryMovie = (id)=>{
    setCategoryMovie(id)
  }
  const changeSearchResults = (res)=>{
    setSeachResults(res)
  }
  return (
    <DataContext.Provider
      value={{
        controlNew,
        categorySerie,
        categoryMovie,
        data,
        categories,
        progress,
        progressSerie,
        progressTV,
        allowAdult,
        listSearch,
        searchResults,
        changeCategoryMovie,
        changeCategorySerie,
        AllowAdultContent,
        controlNewData,
        addData,
        addList,
        changeSearchResults,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
