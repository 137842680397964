import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CheckProfile = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const profileSelected = JSON.parse(
      localStorage.getItem("@beTVProfileSelected")
    );
    const checkProfile = () => {
      if (!profileSelected) {
        navigate("/profiles");
        return;
      } else if (profileSelected) {
        navigate(window.location.pathname);
        return;
      }
    };
    checkProfile();
  }, [navigate]);

  return children;
};

export default CheckProfile;
