import "./Aside.css";
import { useNavigate, NavLink } from "react-router-dom";

import { FaExchangeAlt, FaHome } from "react-icons/fa";
import { TbMovie } from "react-icons/tb";
import { MdOutlineMovieFilter } from "react-icons/md";
import { PiTelevisionBold } from "react-icons/pi";
import { FaGear } from "react-icons/fa6";
import { TbReload } from "react-icons/tb";

import { UserContext } from "../../context/userContext";
import { DataContext } from "../../context/dataContext";

import { useContext } from "react";

const Aside = ({active}) => {
  const { user } = useContext(UserContext);
  const { progressSerie, progressTV, progress, controlNewData } =
    useContext(DataContext);
  //user é o profile selecionado
  //userData é o stream + informacoes fornecidas do xtream
  const navigate = useNavigate();
  const widthLoad = "60px";
  const animationLoad = "load 2s infinite";
  return (
    <aside>
      <div className="logoProfilecontent">
        <div className="menuLogo"></div>
        <div className="profileSectionMenu">
          <div
            className="profileAvatar avatarMenu"
            style={{ backgroundImage: `url('${user && `../../../${user.url}`}')` }}
          ></div>
          <span>{user && user.name}</span>
          <div
            className="changeProfileMenu"
            onClick={(_) => navigate("/profiles")}
          >
            <FaExchangeAlt />
          </div>
        </div>
      </div>
      <div className="menuContent">
        <div className="menuLinkLoad">
          <div className="menuLoadProgress">
            <NavLink to={"/browser"} className={`${active ? 'active' : 'ddd'}`}>
              <FaHome size={32} />
            </NavLink>
          </div>
        </div>
        <div className="menuLinkLoad">
          {progress ? (
            <div
              className="menuProgressBar"
              style={{ width: widthLoad, animation: animationLoad }}
            ></div>
          ) : (
            <div
              className="menuProgressBar"
              style={{ width: "0", animation: "" }}
            ></div>
          )}
          <div className="menuLoadProgress">
            {progress ? (
              <NavLink>
                <TbMovie size={32} />
              </NavLink>
            ) : (
              <NavLink to={"/movies"}>
                <TbMovie size={32} />
              </NavLink>
            )}
          </div>
        </div>
        <div className="menuLinkLoad">
          {progressSerie ? (
            <div
              className="menuProgressBar"
              style={{ width: widthLoad, animation: animationLoad }}
            ></div>
          ) : (
            <div
              className="menuProgressBar"
              style={{ width: "0", animation: "" }}
            ></div>
          )}
          <div className="menuLoadProgress">
            {progressSerie ? (
              <NavLink>
                <MdOutlineMovieFilter size={32} />
              </NavLink>
            ) : (
              <NavLink to={"/series"}>
                <MdOutlineMovieFilter size={32} />
              </NavLink>
            )}
          </div>
        </div>
        <div className="menuLinkLoad">
          {progressTV ? (
            <div
              className="menuProgressBar"
              style={{ width: widthLoad, animation: animationLoad }}
            ></div>
          ) : (
            <div
              className="menuProgressBar"
              style={{ width: "0", animation: "" }}
            ></div>
          )}
          <div className="menuLoadProgress">
            {progressTV ? (
              <NavLink>
                <PiTelevisionBold size={32} />
              </NavLink>
            ) : (
              <NavLink to={"/live"}>
                <PiTelevisionBold size={32} />
              </NavLink>
            )}
          </div>
        </div>
      </div>
      <div className="functionsContent">
        <TbReload size={18} onClick={(_) => controlNewData()} />
        <NavLink to={"/config"}>
          <FaGear size={28} />
        </NavLink>
      </div>
    </aside>
  );
};

export default Aside;
