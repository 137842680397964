import "./New.css";
import { UserContext } from "../../context/userContext";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const New = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { changeUser, userData, changeNew } = useContext(UserContext);

  const resetValues = () => {
    setName("");
    setPassword("");
    setUrl("");
    setUser("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData && userData.length >= 4) {
      toast.error("Você atingiu o limite de usuarios, por favor exclua algum.");
    } else {
      let finded = userData && userData.find((el)=>{
        return el.dado.name === name
      })
      // verificar se já existe o mesmo nome para evitar conflitos futuros
      if(finded){
        toast.warn("Já existe um usuario com esse nome, por favor escolha outro")
      }else{
        if (userData && userData.length > 0) {
          if (!url || !name || !user || !password) {
            toast.error("Por favor preencha todos os campos");
          } else {
            if (name.length < 3) {
              return toast.warn(
                "Por favor o nome deve conter no minimo 3 caracteres"
              );
            }
            if (
              !url.toLocaleLowerCase().includes("http://") &&
              !url.toLocaleLowerCase().includes("https://")
            ) {
              return toast.warn(
                'Por favor a URL deve conter "http://" ou "https://" '
              );
            }
            setLoading(true);
            try {
              let haveItem = userData && userData.find((el) => {
                return el.dado.url === url;
              });
              if (haveItem) {
                setLoading(false);
                return toast.warn(
                  "O Stram cadastrado já está na sua lista, use outra url"
                );
              }
              const resp = await axios.get(
                `${url}/player_api.php?username=${user}&password=${password}`
              );
              let info = resp.data.user_info;
              let dado = {
                name,
                url,
                user,
                password,
              };
              let arr = [...userData, { dado, info }];
              localStorage.setItem("@beTVStreams", JSON.stringify(arr));
              changeUser(arr);
              setLoading(false);
              toast.success("Usuario adicionado com sucesso");
              resetValues();
              changeNew();
              navigate("/select");
            } catch (error) {
              setLoading(false);
              console.log(error);
              resetValues();
              toast.error(
                "Ocorreu um erro, cheque as informações e tente novamente"
              );
            }
          }
        } else {
          if (!url || !name || !user || !password) {
            toast.error("Por favor preencha todos os campos");
          } else {
            if (name.length < 3) {
              return toast.warn(
                "Por favor o nome deve conter no minimo 3 caracteres"
              );
            }
            if (
              !url.toLocaleLowerCase().includes("http://") &&
              !url.toLocaleLowerCase().includes("https://")
            ) {
              return toast.warn(
                'Por favor a URL deve conter "http://" ou "https://" '
              );
            }
            setLoading(true);
            try {
              const resp = await axios.get(
                `${url}/player_api.php?username=${user}&password=${password}`
              );
              let info = resp.data.user_info;
              let dado = {
                name,
                url,
                user,
                password,
              };
              let arr = [{ dado, info }];
              localStorage.setItem("@beTVStreams", JSON.stringify(arr));
              changeUser(arr);
              setLoading(false);
              toast.success("Usuario adicionado com sucesso");
              resetValues();
              changeNew();
              navigate("/select");
            } catch (error) {
              setLoading(false);
              console.log(error);
              toast.error(
                "Ocorreu um erro, cheque as informações e tente novamente"
              );
            }
          }
        }
      }
    }
  };
  return (
    <div className="newPageContainer">
      <div className="addProfile">
        <div className="logoTv"></div>
        <h4>
          Parece que você não tem nenhum Stream cadastrado ainda, <br></br>{" "}
          adicione um para começar a diversão!
        </h4>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            name="name"
            id="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Nome"
          />
          <input
            type="text"
            name="url"
            id="url"
            onChange={(e) => setUrl(e.target.value.toLocaleLowerCase())}
            value={url}
            style={{textTransform: 'lowercase'}}
            placeholder="https://seulink.com"
          />
          <input
            type="text"
            name="user"
            id="user"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            placeholder="Usuario"
          />
          <input
            type="text"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Senha"
          />
          {loading ? (
            <button className="submitNew" disabled>
              Salvando
            </button>
          ) : (
            <>
              <button className="submitNew" >Salvar</button>
              {userData && userData.length > 0 && <div className="backButton" onClick={_=>navigate('/select')}>Voltar</div>}
            </>
          )}
        </form>
      </div>

      <div className="newBackground"></div>
    </div>
  );
};

export default New;
