import { useRef } from "react";
import "./Wraper.css";
import { motion } from "framer-motion";

const Wraper = ({ children, gap, refContainer, controls,distance }) => {
  const containerRef = useRef();

  return (
    <motion.div
      ref={containerRef}
      drag={"x"}
      animate={{x: distance}}
      dragConstraints={
        (containerRef.current && containerRef.current.offsetWidth) <
        (refContainer.current && refContainer.current.offsetWidth)
          ? containerRef
          : refContainer
      }
      className="wraperSlider"
      style={{
        gap,
        paddingBottom: "10px",
        padding: `${controls ? '0 40px 0 40px' : 0}`

      }}
      whileTap={{ cursor: "grabbing" }}
    >
      {children}
    </motion.div>
  );
};

export default Wraper;
