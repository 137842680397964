import "./Movie.css";
//comptonents e pages
import Aside from "../../components/Aside/Aside";
import SearchBar from "../../components/SearchBar/SearchBar";
import Back from "../../components/Back/Back";
//contexts
import { DataContext } from "../../context/dataContext";
import { UserContext } from "../../context/userContext";
//modules
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactLoading from "react-loading";
import ReactPlayer from "react-player";
//icons
import { FaClock, FaPlay, FaYoutube } from "react-icons/fa";
import Loading from "../../components/Loading/Loading";

const Movie = () => {
  const navigate = useNavigate();
  const { data } = useContext(DataContext);
  const { userData, progress } = useContext(UserContext);
  const [actors, setAcctors] = useState([]);
  const [movie, setMovie] = useState({});
  const [searchVideo, setSearchVideo] = useState(false);
  const [trailer, setTrailer] = useState({});
  const [movieDB, setMovieDB] = useState({});
  const [loadActors, setLoadActors] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    console.log("effecting movie");
    if (userData && data.movies) {
      let findedMovie =
        data &&
        data.movies &&
        data.movies.find((el) => {
          return el.stream_id === parseInt(id);
        });
      if (findedMovie) {
        const getPosters = async () => {
          let resp = await axios.get(
            `${userData.dado.url}/player_api.php?username=${userData.dado.user}&password=${userData.dado.password}&action=get_vod_info&vod_id=${findedMovie.stream_id}`
          );
          setMovie(resp.data);
        };

        getPosters();
      }
    } else {
      navigate("/movies");
    }
  }, [userData, data, id, progress, navigate]);

  //getting movieDB
  useEffect(() => {
    const getMovieDB = async () => {
      if (movie && movie.movie_data) {
        try {
          let respTMDB = await axios.get(
            `https://api.themoviedb.org/3/search/movie?query=${movie.movie_data.title}&include_adult=true&language=pt-BR&page=1&year=${movie.movie_data.year}`,
            {
              headers:
                "Authorization: Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjNTRmMGM2MDQxM2E4NzBkYzYwMTQ5OGJjMTRkYjg0ZiIsInN1YiI6IjYxZTE4YTUyMTI0MjVjMDA0NDkwNDM1NSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.hzKNDn2KEyUD5nJViXRdxJX4kMGRwrVaeCpknsYxUEs",
            }
          );
          respTMDB && setMovieDB(respTMDB.data.results[0]);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getMovieDB();
  }, [movie]);
  ///getting actors
  useEffect(() => {
    setLoadActors(true);
    let actorsInformation = [];
    let actorsList = [];
    if (movie) {
      movie.info &&
      movie.info.actors &&
        movie.info.actors.split(",").forEach((el) => {
          actorsList.push(el);
        });
      actorsList.forEach(async (dado) => {
        let respdbact = await axios.get(
          `https://api.themoviedb.org/3/search/person?query=${dado}&include_adult=true&language=pt-BR&page=1`,
          {
            headers:
              "Authorization: Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjNTRmMGM2MDQxM2E4NzBkYzYwMTQ5OGJjMTRkYjg0ZiIsInN1YiI6IjYxZTE4YTUyMTI0MjVjMDA0NDkwNDM1NSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.hzKNDn2KEyUD5nJViXRdxJX4kMGRwrVaeCpknsYxUEs",
          }
        );
        respdbact.data.results &&
          setAcctors([...actorsInformation, respdbact.data.results[0]]);
        respdbact.data.results &&
          actorsInformation.push(respdbact.data.results[0]);
        setLoadActors(false);
      });
    }
  }, [movie]);

  //Searching yt trailer
  const handleSearch = async () => {
    setShowTrailer(true);
    setSearchVideo(true);
    try {
      let resp = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=1&q=${movie.movie_data.title} trailer br&type=video&key=AIzaSyC4Q-yXZqAcM1f8mejfGlxpNQvBjaN9r0I`
      );
      resp.data.items && setTrailer(resp.data.items[0].id.videoId);
      setSearchVideo(false);
    } catch (error) {
      console.log(error);
      setSearchVideo(false);
    }
  };
  const handlePlay = (id) => {
    localStorage.setItem("@moviePlaying", JSON.stringify(movie));
    navigate(`/movies/movie/play/${id}`);
  };

  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div className="browserContent">
        {movie && movie.info && movie.info.name ? (
          <>
            <div
              className="topBrowser topDetails"
              style={{
                backgroundImage: `${`url("${
                  movie.info.backdrop_path && movie.info.backdrop_path[0]
                }")`}`,
              }}
            >
              <Back route="/movies" />
              <SearchBar />
              {movie.movie_data.category_id === "41" ? (
                <div className="movieTitle adultTitle">{movie.movie_data.name}</div>
              ) : (
                <div className="movieTitle">{movie.movie_data.name}</div>
              )}
              <div className="movieOverview">
                {movieDB ? movieDB.overview : movie.info.description}
              </div>
              <div className="movieDetailsContainer">
                <span>
                  <FaClock size={18} />
                  {movie.info.duration}
                </span>
                {movie.info.rating > 0 && (
                  <span>{parseFloat(movie.info.rating).toFixed(1)}</span>
                )}
                {movie.info.genre && (
                  <div className="genresSpanContent">
                    {movie.info.genre.split(",").map((item, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: "var(--yellowColor)",
                          borderRadius: "4px",
                          color: "var(--black)",
                          fontWeight: "bold",
                        }}
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className="actionsButtonDetail">
                <div className="playerSectionButton">
                  <FaPlay size={16} />{" "}
                  <span onClick={(_) => handlePlay(movie.movie_data.stream_id)}>
                    Assistir
                  </span>
                </div>
                <div
                  className="playerSectionButton"
                  onClick={(_) => handleSearch()}
                >
                  {searchVideo ? (
                    <>
                      <ReactLoading height={22} width={22} type="spin" />{" "}
                      <span>Carregando</span>
                    </>
                  ) : (
                    <>
                      <FaYoutube /> <span>Trailer</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="contentBrowser contentDeatails">
              <div className="contentDetailsContainer">
                {trailer && showTrailer && (
                  <ReactPlayer
                    height={200}
                    width={340}
                    loop
                    playing
                    url={`https://youtube.com/embed/${trailer}`}
                  />
                )}
                <div className="actorsSection">
                  {loadActors ? (
                    <span>Carregando</span>
                  ) : (
                    actors &&
                    actors.map((item, index) => (
                      <div className="actorBox" key={index}>
                        <div
                          className="actorPoster"
                          style={{
                            backgroundImage: `url('https://image.tmdb.org/t/p/original/${
                              item && item.profile_path
                            }')`,
                          }}
                        >
                          <span>{item && item.name}</span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="newBackground backgroundBrowser"></div>
    </div>
  );
};

export default Movie;
