import "./Loading.css";

const Loading = () => {
  return (
    <div className="loadingComponent">
      <div className="loadingCircle">
        <div className="loadContainer">
        <div className="loadMovment">
          <div className="loadDot"></div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
