import "./Serie.css";
//comptonents e pages
import Aside from "../../components/Aside/Aside";
import SearchBar from "../../components/SearchBar/SearchBar";
import Back from "../../components/Back/Back";
import Slider from "../../components/Slider/Slider";
import Slide from "../../components/Slider/Slide/Slide";
//contexts
import { DataContext } from "../../context/dataContext";
import { UserContext } from "../../context/userContext";
//modules
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactLoading from "react-loading";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
//icons
import { FaClock, FaPlay, FaYoutube } from "react-icons/fa";
import { TbReload } from "react-icons/tb";
import Loading from "../../components/Loading/Loading";

const Serie = () => {
  const navigate = useNavigate();
  const { data } = useContext(DataContext);
  const { userData, progress } = useContext(UserContext);
  const [user,setUser] = useState()
  const [serie, setSerie] = useState({});
  const [searchVideo, setSearchVideo] = useState(false);
  const [trailer, setTrailer] = useState({});
  const [movieDB, setMovieDB] = useState({});
  const [showTrailer, setShowTrailer] = useState(false);
  const [season, setSeason] = useState(1);
  const [controlEp, setControlEP] = useState(null);
  const [controlSerie, setControlSerie] = useState(false);
  const [recentEps, setRecentEps] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    let epsRecent = [];
    user &&
      user.recent &&
      user.recent.length > 0 &&
      (epsRecent = user.recent.find(
        (el) =>
          el.type === "serie" &&
          (el.name === serie.name || el.title === serie.title)
      ).episodes);
    setRecentEps(epsRecent);
  }, [serie.name, serie.title, user]);

  useEffect(() => {
    let userSerieInfo = JSON.parse(
      localStorage.getItem("@beTVProfileSelected")
    );
    setUser(userSerieInfo)
    if (userSerieInfo.recent) {
      let listOfSeriesinRecent = userSerieInfo.recent.filter(
        (el) => el.type === "serie"
      );
      let haveSerie =
        listOfSeriesinRecent &&
        listOfSeriesinRecent.find(
          (el) => el.name && el.name === (serie.info && serie.info.name)
        );

      if (haveSerie) {
        setControlSerie(true);
        setControlEP(haveSerie.episodes[haveSerie.episodes.length - 1].id);
      }
    }
  }, [serie]);

  useEffect(() => {
    console.log("effecting serie");
    if (userData && data.series) {
      let findedSerie =
        data &&
        data.series &&
        data.series.find((el) => {
          return el.series_id === parseInt(id);
        });
      if (findedSerie) {
        const getPosters = async () => {
          try {
            let resp = await axios.get(
              `${userData.dado.url}/player_api.php?username=${
                userData.dado.user
              }&password=${
                userData.dado.password
              }&action=get_series_info&series_id=${
                findedSerie.stream_id
                  ? findedSerie.stream_id
                  : findedSerie.series_id
              }`
            );
            setSerie(resp.data);
          } catch (error) {
            console.log(error);
          }
        };

        getPosters();
      } else {
        navigate("/series");
      }
    } else {
      navigate("/series");
    }
  }, [userData, data, id, progress, navigate]);

  //getting serieDB
  useEffect(() => {
    const getMovieDB = async () => {
      if (serie && serie.info) {
        try {
          let respTMDB = await axios.get(
            `https://api.themoviedb.org/3/search/tv?query=${serie.info.title}&include_adult=true&language=pt-BR&page=1`,
            {
              headers:
                "Authorization: Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjNTRmMGM2MDQxM2E4NzBkYzYwMTQ5OGJjMTRkYjg0ZiIsInN1YiI6IjYxZTE4YTUyMTI0MjVjMDA0NDkwNDM1NSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.hzKNDn2KEyUD5nJViXRdxJX4kMGRwrVaeCpknsYxUEs",
            }
          );
          respTMDB && setMovieDB(respTMDB.data.results[0]);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getMovieDB();
  }, [serie]);

  //Searching yt trailer
  const handleSearch = async () => {
    setShowTrailer(true);
    setSearchVideo(true);
    try {
      let resp = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=1&q=${serie.info.title} trailer br&type=video&key=AIzaSyC4Q-yXZqAcM1f8mejfGlxpNQvBjaN9r0I`
      );
      resp.data.items && setTrailer(resp.data.items[0].id.videoId);
      setSearchVideo(false);
    } catch (error) {
      console.log(error);
      setSearchVideo(false);
    }
  };
  const handlePlay = (idep) => {
    localStorage.setItem("@seriePlaying", JSON.stringify(serie));
    navigate(`/series/serie/play/${idep}`);
  };

  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div className="browserContent">
        {serie && serie.info && serie.info.name ? (
          <>
            <div
              className="topBrowser topDetails"
              style={{
                backgroundImage: `${`url("${
                  serie.info.backdrop_path && serie.info.backdrop_path[0]
                }")`}`,
              }}
            >
              <Back route="/series" />
              <SearchBar />
              {serie.info.category_id === "41" ? (
                <div className="movieTitle adultTitle">{serie.info.name}</div>
              ) : (
                <div className="movieTitle">{serie.info.name}</div>
              )}
              <div className="movieOverview">
                {movieDB ? movieDB.overview : serie.info.plot}
              </div>
              <div className="movieDetailsContainer">
                {serie.info.episode_run_time && (
                  <span>
                    <FaClock size={18} />
                    {serie.info.episode_run_time}min
                  </span>
                )}

                {serie.info.rating > 0 && (
                  <span>{parseFloat(serie.info.rating).toFixed(1)}</span>
                )}
                {serie.info.genre && (
                  <div className="genresSpanContent">
                    {serie.info.genre.split(",").map((item, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: "var(--yellowColor)",
                          borderRadius: "4px",
                          color: "var(--black)",
                          fontWeight: "bold",
                        }}
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className="actionsButtonDetail">
                {controlSerie ? (
                  <>
                    <div
                      className="playerSectionButton"
                      onClick={(_) => handlePlay(controlEp)}
                    >
                      <FaPlay size={16} /> <span>Continuar</span>
                    </div>
                    <div
                      className="playerSectionButton"
                      onClick={(_) =>
                        handlePlay(Object.values(serie.episodes)[0][0].id)
                      }
                    >
                      <TbReload size={22} /> <span>Reiniciar</span>
                    </div>
                  </>
                ) : (
                  <div
                    className="playerSectionButton"
                    onClick={(_) =>
                      handlePlay(Object.values(serie.episodes)[0][0].id)
                    }
                  >
                    <FaPlay size={16} /> <span>Assistir</span>
                  </div>
                )}
                <div
                  className="playerSectionButton"
                  onClick={(_) => handleSearch()}
                >
                  {searchVideo ? (
                    <>
                      <ReactLoading height={22} width={22} type="spin" />{" "}
                      <span>Carregando</span>
                    </>
                  ) : (
                    <>
                      <FaYoutube /> <span>Trailer</span>
                    </>
                  )}
                </div>
              </div>
              {trailer && showTrailer && (
                <div className="trailerSerie">
                  <ReactPlayer
                    height={200}
                    width={340}
                    loop
                    playing
                    url={`https://youtube.com/embed/${trailer}`}
                  />
                </div>
              )}
            </div>
            <div className="contentBrowser contentDeatails">
              <div className="contentDetailsContainer SerieContainerDetail">
                <div className="seasonsTab">
                  {serie &&
                    serie.episodes &&
                    Object.keys(serie.episodes).map((item, index) => (
                      <span
                        className="spanTabSeason"
                        key={index}
                        onClick={(_) => setSeason(index + 1)}
                        style={{
                          backgroundColor: `${
                            index + 1 === season
                              ? "var(--black)"
                              : "var(--cleanColor)"
                          }`,
                          color: `${
                            index + 1 === season
                              ? "var(--yellowColor)"
                              : "var(--black)"
                          }`,
                        }}
                      >
                        {item.name} <span>Temporada {index + 1}</span>
                      </span>
                    ))}
                </div>
                <Slider bgColor={"var(--black)"}>
                  {serie &&
                    serie.episodes &&
                    serie.episodes[season] &&
                    serie.episodes[season].map((item, index) => (
                      <Slide key={index}>
                        <motion.div
                          className="episodeBox"
                          onTap={(_) => handlePlay(item.id)}
                        >
                          <div
                            className="episodePoster no-drag"
                            style={{
                              backgroundImage: `url('${
                                item.info.cover_big
                                  ? item.info.cover_big
                                  : serie.info.backdrop_path
                                  ? serie.info.backdrop_path
                                  : ""
                              }')`,
                            }}
                          >
                            <div>{item.title}</div>
                            {item.info && item.info.duration}
                            {user &&
                              user.recent &&
                              user.recent.length > 0 &&
                              recentEps.find((el) => el.id === item.id) && (
                                <div className="progressEpBox">
                                  <div
                                    className="progressEpBar"
                                    style={{
                                      width: `${
                                        (100 *
                                          recentEps.find((el) => el.id === item.id).time) /
                                        (item.info.duration_secs)
                                      }%`,
                                    }}
                                  >
                                  </div>
                                </div>
                              )}
                          </div>
                        </motion.div>
                      </Slide>
                    ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}{" "}
      </div>
      <div className="newBackground backgroundBrowser"></div>
    </div>
  );
};

export default Serie;
