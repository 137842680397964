import "./Movies.css";
import Aside from "../../components/Aside/Aside";

import { DataContext } from "../../context/dataContext";
import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar/SearchBar";
import Slider from "../../components/Slider/Slider";
import Slide from "../../components/Slider/Slide/Slide";
import bcrypt from "bcryptjs-react";
import {motion} from 'framer-motion'
import Loading from "../../components/Loading/Loading";

const Movies = () => {
  const [page, setPage] = useState(1);
  const [pageInit, setPageInit] = useState(0);
  const [pageFim, setPageFim] = useState(145);
  const container = useRef();

  const {
    data,
    categories,
    progress,
    AllowAdultContent,
    allowAdult,
    changeCategoryMovie,
    categoryMovie,
  } = useContext(DataContext);
  const [selected, setSelected] = useState(categoryMovie ? categoryMovie : 0);
  const [controlModal, setControlModal] = useState(false);
  const [controlAdult, setControlAdult] = useState(
    allowAdult ? allowAdult : false
  );
  const [controlNewp, setControlNewP] = useState(false);
  const [passInput, setPassInput] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const navigate = useNavigate();

  const handleNext = () => {
    container.current.scrollTop = 0;
    if (selected !== 0) {
      if (
        data &&
        data.movies &&
        data.movies
          .filter((el) =>
            el.category_ids.find((dat) => dat === parseInt(selected))
          )
          .slice(pageInit, pageFim).length < 145
      ) {
        toast.warn("Você já esá na ulima pagina");
      } else {
        if (page >= 1) {
          pageInit >= 145 ? setPageInit(pageInit + 145) : setPageInit(145);
          pageFim >= 145 ? setPageFim(pageFim + 145) : setPageFim(145);
          setPage(page + 1);
        }
      }
    } else {
      if (
        data &&
        data.movies &&
        data.movies.slice(pageInit, pageFim).length < 145
      ) {
        toast.warn("Você já esá na ulima pagina");
      } else {
        if (page >= 1) {
          pageInit >= 145 ? setPageInit(pageInit + 145) : setPageInit(145);
          pageFim >= 145 ? setPageFim(pageFim + 145) : setPageFim(145);
          setPage(page + 1);
        }
      }
    }
  };
  const handlePrev = () => {
    container.current.scrollTop = 0;
    if (page >= 2) {
      pageInit > 145 ? setPageInit(pageInit - 145) : setPageInit(0);
      pageFim > 145 ? setPageFim(pageFim - 145) : setPageFim(145);
      setPage(page - 1);
    } else {
      toast.warn("Você já esta na primeira pagina");
    }
  };

  const handleSelect = (id) => {
    setPage(1);
    setPageInit(0);
    setPageFim(145);
    changeCategoryMovie(id);
    id ? setSelected(id) : setSelected(0);
  };

  const handleAdult = () => {
    if (!controlAdult) {
      setControlModal(true);
    }
    let password = JSON.parse(localStorage.getItem("@passCodeAdultbeTV"));
    if (password) {
      setControlNewP(false);
    } else {
      setControlNewP(true);
    }
    // setControlAdult(!controlAdult)
  };
  const handleNewPasssword = (e) => {
    e.preventDefault();
    if (!passInput || !confirmPass) {
      return toast.warn("Preencha todos os campos");
    } else if (passInput !== confirmPass) {
      return toast.warn("As senhas digitas sao diferentes");
    } else {
      let hashed = bcrypt.hashSync(passInput, 10);
      localStorage.setItem("@passCodeAdultbeTV", JSON.stringify(hashed));
      toast.success("Senha criada com sucesso");
      setControlAdult(true);
      AllowAdultContent(true);
      setConfirmPass("");
      setPassInput("");
      setControlModal(false);
    }
  };
  const handleConfirmPass = (e) => {
    e.preventDefault();
    if (!passInput || !confirmPass) {
      return toast.warn("Preencha todos os campos");
    } else if (passInput !== confirmPass) {
      return toast.warn("As senhas digitas sao diferentes");
    } else {
      let password = JSON.parse(localStorage.getItem("@passCodeAdultbeTV"));
      bcrypt.compare(passInput, password, (err, match) => {
        if (!match) {
          toast.error("Senhas incorreta");
        } else if (match) {
          toast.success("Liberado");
          setControlAdult(true);
          AllowAdultContent(true);
          setConfirmPass("");
          setPassInput("");
          setControlModal(false);
        }
      });
    }
  };

  return (
    <div className="newPageContainer browsercontainer">
      <Aside />
      <div className="browserContent moviesContent">
        {/* Modal for set adullt password */}
        {controlModal && (
          <div
            className="modalConfigAdult"
            onClick={(_) => setControlModal(false)}
          ></div>
        )}
        {controlModal && (
          <div className="passwordConfig">
            {controlNewp ? (
              <form onSubmit={(e) => handleNewPasssword(e)}>
                <span>Cadastre uma senha para controle de conteudo adulto</span>
                <input
                  type="password"
                  name="password"
                  placeholder="Digite a senha"
                  value={passInput}
                  onChange={(e) => setPassInput(e.target.value)}
                />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme a senha"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
                <button>Salvar</button>
              </form>
            ) : (
              <form onSubmit={(e) => handleConfirmPass(e)}>
                <span>Digite a senha:</span>
                <input
                  type="password"
                  name="password"
                  placeholder="Digite a senha"
                  value={passInput}
                  onChange={(e) => setPassInput(e.target.value)}
                />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme a senha"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
                <button>Confirmar</button>
              </form>
            )}
          </div>
        )}
        {!progress && data && data.movies ? (
          <div className="moviesContainer" ref={container}>
            <div className="topMoviesContainer">
              <Slider>
                <Slide>
                  <motion.div
                  className="span"
                    onTap={(e) => handleSelect()}
                    style={{
                      backgroundColor:
                        selected === 0
                          ? "var(--yellowHover)"
                          : "var(--bgTransparent)",
                      color: selected === 0 ? "var(--black)" : "white",
                    }}
                  >
                    Todos
                  </motion.div>
                </Slide>
                {categories &&
                  categories.movies &&
                  categories.movies.map((item, index) => (
                    <Slide key={index}>
                      <motion.div
                      className="span"
                        onTap={(e) => handleSelect(item.category_id)}
                        category={item}
                        style={{
                          backgroundColor:
                            selected === item.category_id
                              ? "var(--yellowHover)"
                              : "var(--bgTransparent)",
                          color:
                            selected === item.category_id
                              ? "var(--black)"
                              : "white",
                        }}
                      >
                        {item.category_name.split("|").length > 1
                          ? item.category_name.split("|")[1]
                          : item.category_name.split("|")[0]}
                      </motion.div>
                    </Slide>
                  ))}
              </Slider>
            </div>
            <div className="moviesPagination">
              <span className="pageDesc">Pagina {page}</span>
              <div
                className="
              searchAndControl"
              >
                {controlAdult ? (
                  <div className="adultControl">
                    Conteudo adulto:
                    <div
                      className="controlAdultBox"
                      onClick={(_) => {
                        setControlAdult(false);
                        AllowAdultContent(false);
                      }}
                    >
                      <div
                        className={`${
                          controlAdult
                            ? "controlAdultBall AdultRight"
                            : "controlAdultBall"
                        }`}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div className="adultControl">
                    Conteudo adulto:
                    <div
                      className="controlAdultBox"
                      onClick={(_) => handleAdult()}
                    >
                      <div
                        className={`${
                          controlAdult
                            ? "controlAdultBall AdultRight"
                            : "controlAdultBall"
                        }`}
                      ></div>
                    </div>
                  </div>
                )}
                <SearchBar pagesSearch navigation={true} activePage={"movies"} />
              </div>
              <div className="controlsPage">
                <span onClick={(_) => handlePrev()}>Anterior</span>
                <span onClick={(_) => handleNext()}>Proxima</span>
              </div>
            </div>
            <div className="moviesContent">
              {data && data.movies && data.movies.length >= 1 ? (
                selected === 0 ? (
                  // show all movies
                  data &&
                  data.movies &&
                  (allowAdult
                    ? data.movies.slice(pageInit, pageFim)
                    : data.movies
                        .filter((el) => el.category_id !== "41")
                        .slice(pageInit, pageFim)
                  ).map((item, index) =>
                    item.category_id !== "41" ? (
                      //normal content box
                      <div
                        className="movieBox"
                        key={index}
                        title={item.name}
                        onClick={(_) =>
                          navigate(`/movies/movie/${item.stream_id}`)
                        }
                      >
                        <div
                          className="moviePoster"
                          style={{
                            backgroundImage: `url('${item.stream_icon}')`,
                          }}
                        >
                          {item.year && (
                            <div className="moviePosterInfo movieYear">
                              {item.year}
                            </div>
                          )}
                          {item.rating > 0 && (
                            <div className="moviePosterInfo movieNote">
                              {parseFloat(item.rating).toFixed(1)}
                            </div>
                          )}

                          {item.name.toLowerCase().includes("[l]") && (
                            <div className="moviePosterInfo movieLegend">L</div>
                          )}
                        </div>
                        <div className="movieInfos">
                          <div className="movieTitle">
                            {item.name.split("[").length > 1
                              ? item.name.split("[")[0]
                              : item.name.split("(")[0]}
                          </div>
                        </div>
                      </div>
                    ) : (
                      // XXx content box
                      controlAdult && (
                        <div
                          className="movieBox"
                          key={index}
                          onClick={(_) =>
                            navigate(`/movies/movie/${item.stream_id}`)
                          }
                        >
                          <div
                            className="moviePoster"
                            style={{
                              backgroundImage: `url('https://p6.vc/AAER')`,
                            }}
                          >
                            {item.year && (
                              <div className="moviePosterInfo movieYear">
                                {item.year}
                              </div>
                            )}
                            {item.rating > 0 && (
                              <div className="moviePosterInfo movieNote">
                                {parseFloat(item.rating).toFixed(1)}
                              </div>
                            )}

                            {item.name.toLowerCase().includes("[l]") && (
                              <div className="moviePosterInfo movieLegend">
                                L
                              </div>
                            )}
                          </div>
                          <div className="movieInfos">
                            <div className="movieTitle">{item.name}</div>
                          </div>
                        </div>
                      )
                    )
                  )
                ) : (
                  //show category movie

                  data &&
                  data.movies &&
                  data.movies
                    .filter((el) =>
                      el.category_ids.find((dat) => dat === parseInt(selected))
                    )
                    .slice(pageInit, pageFim)
                    .map((item, index) =>
                      item.category_id !== "41" ? (
                        //normal content box
                        <div
                          className="movieBox"
                          key={index}
                          title={item.name}
                          onClick={(_) =>
                            navigate(`/movies/movie/${item.stream_id}`)
                          }
                        >
                          <div
                            className="moviePoster"
                            style={{
                              backgroundImage: `url('${item.stream_icon}')`,
                            }}
                          >
                            {item.year && (
                              <div className="moviePosterInfo movieYear">
                                {item.year}
                              </div>
                            )}
                            {item.rating > 0 && (
                              <div className="moviePosterInfo movieNote">
                                {parseFloat(item.rating).toFixed(1)}
                              </div>
                            )}

                            {item.name.toLowerCase().includes("[l]") && (
                              <div className="moviePosterInfo movieLegend">
                                L
                              </div>
                            )}
                          </div>
                          <div className="movieInfos">
                            <div className="movieTitle">
                              {item.name.split("[").length > 1
                                ? item.name.split("[")[0]
                                : item.name.split("(")[0]}
                            </div>
                          </div>
                        </div>
                      ) : (
                        // XXx content box
                        controlAdult && (
                          <div
                            className="movieBox"
                            key={index}
                            onClick={(_) =>
                              navigate(`/movies/movie/${item.stream_id}`)
                            }
                          >
                            <div
                              className="moviePoster"
                              style={{
                                backgroundImage: `url('https://p6.vc/AAER')`,
                              }}
                            >
                              <div className="moviePosterInfo movieYear">
                                {item.year}
                              </div>
                              {item.rating > 0 && (
                                <div className="moviePosterInfo movieNote">
                                  {parseFloat(item.rating).toFixed(1)}
                                </div>
                              )}

                              {item.name.toLowerCase().includes("[l]") && (
                                <div className="moviePosterInfo movieLegend">
                                  L
                                </div>
                              )}
                            </div>
                            <div className="movieInfos">
                              <div className="movieTitle">{item.name}</div>
                            </div>
                          </div>
                        )
                      )
                    )
                )
              ) : (
                <h1>Nenhum filme encontrado</h1>
              )}
            </div>
            <div className="moviesPagination">
              <span className="pageDesc">Pagina {page}</span>
              <div className="controlsPage">
                <span onClick={(_) => handlePrev()}>Anterior</span>
                <span onClick={(_) => handleNext()}>Proxima</span>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Movies;
