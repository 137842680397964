import React from 'react'
import { useEffect,useState } from 'react';
import convert from 'xml-js'
const Tes = () => {
    const [epg,setEPG] = useState(null)
   useEffect(() => {
    const getEPG = async () => {
      try {
        fetch("http://pfsv.io/xmltv.php?username=9913789670&password=80139")
          .then((res) => res.text())
          .then((restext) => {
            setEPG(JSON.parse(convert.xml2json(restext, {compact: true, spaces: 1})));
          });
          
      } catch (error) {
        console.log(error);
      }
    };
    getEPG();
  }, []);
  return (
    <div>{console.log(epg)}</div>
  )
}

export default Tes