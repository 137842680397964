import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CheckXstream = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const streamSelected = JSON.parse(
      localStorage.getItem("@beTVStreamSelected")
    );
    const data = JSON.parse(localStorage.getItem("@beTVStreams"));
    const checkXstream = () => {
      if (!streamSelected) {
        if (data) {
          navigate("/select");
          return;
        } else {
          navigate("/newprofile");
          return;
        }
      }
    };
    checkXstream();
  }, [navigate]);

  return children;
};

export default CheckXstream;
