import { useContext, useState } from "react";
import { DataContext } from "../../context/dataContext";
import "./SearchBar.css";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ navigation, activePage, browserSearch, pagesSearch }) => {
  const [searchInput, setSerachInput] = useState("");
  const { listSearch } = useContext(DataContext);
  const [browserControl, setBrowsercontrol] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchControl, setSearchControl] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (value) => {
    setSearchControl(true);
    setSerachInput(value);
    if (value || value !== "") {
      if (activePage === "movies") {
        let findedMov =
          listSearch.movies &&
          listSearch.movies.filter(
            (el) =>
              el.title
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(value.toLowerCase()) && el.category_id !== "41"
          );
        findedMov ? setSearchResult(findedMov) : setSearchResult([]);
      } else if (activePage === "series") {
        let findedSerie =
          listSearch.series &&
          listSearch.series.filter((el) =>
            el.title
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(value.toLowerCase())
          );
        findedSerie ? setSearchResult(findedSerie) : setSearchResult([]);
      }
    } else {
      setSearchControl(false);
      setSearchResult([]);
    }
  };

  const handleSearchBrowser = (value) => {
    setSerachInput(value);
    if (value || value !== "") {
      if (value.length > 2) {
        setBrowsercontrol(true);
        let listtoSearch = [
          ...listSearch.movies,
          ...listSearch.series,
          ...listSearch.tv,
        ];
        let findedInList = listtoSearch.filter(
          (el) =>
            el.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(value) && el.category_id !== "41"
        );
        findedInList && setSearchResult(findedInList);
      } else {
        setBrowsercontrol(true);
        setSearchResult([]);
      }
    } else {
      setBrowsercontrol(false);
    }
  };
  return (
    <div
      className={`${
        navigation ? "searchBrowser searchStatic" : "searchBrowser"
      }`}
    >
      <div className="searchBar">
        <input
          value={searchInput}
          onChange={(e) =>
            browserSearch
              ? handleSearchBrowser(e.target.value)
              : handleSearch(e.target.value)
          }
          type="text"
          name="searchData"
          id="searchData"
          placeholder="Digite o filme ou serie para pesquisar"
        />
        <div className={`${navigation && "buttonSearchRelative"}`}>
          <FaSearch size={18} />
        </div>
      </div>
      {browserSearch && browserControl && (
        <div className="resultsSearchBar">
          {searchInput.length > 2 ? (
            searchResult.length > 0 ? (
              searchResult.map((item, index) => (
                <div className="resultsItem" key={index}>
                  <div
                    className="resultPoster"
                    style={{
                      backgroundImage: `url(${
                        item.backdrop_path
                          ? item.backdrop_path
                          : item.cover
                          ? item.cover
                          : item.stream_icon
                          ? item.stream_icon
                          : ""
                      })`,
                    }}
                  >
                    <div className="typeResult">
                      {item.stream_type === "movie" ? "Filme" : "Serie"}
                    </div>
                    <div className="titleResult">
                      {item.name ? item.name : item.title}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="resultsItem">
                <div className="typeResult">Nenhum item encontrado</div>
              </div>
            )
          ) : (
            <div className="resultsItem">
              A busca deve ter ao menos 3 caracteres
            </div>
          )}
        </div>
      )}
      {pagesSearch && searchControl && (
        <>
          <div
            className="searchOverlay"
            onClick={(_) => setSearchControl(false)}
          ></div>
          <div className="searchOverlayContent">
            {searchInput.length >= 3 ? (
              searchResult.length > 0 ? (
                searchResult.map((item, index) => (
                  <div
                    className="resultBox"
                    key={index}
                    onClick={(_) => item.stream_id ? navigate(`/movies/movie/${item.stream_id}`) : navigate(`/series/serie/${item.series_id}`)}
                  >
                    <div
                      className="resultPoster"
                      style={{
                        backgroundImage: `url(${
                          item.backdrop_path
                            ? item.backdrop_path
                            : item.cover
                            ? item.cover
                            : item.stream_icon
                            ? item.stream_icon
                            : ""
                        })`,
                      }}
                    >
                      <div className="resultTitle">{item.name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="resultsItem">Nenhum item encontrado</div>
              )
            ) : (
              <div className="resultsItem">
                A busca deve ter ao menos 3 caracteres
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchBar;
